
import {
  AisInstantSearch,
  AisHits,
  AisHighlight,
  AisSearchBox,
  AisPagination,
  AisAutocomplete,
  AisSnippet,
  AisPanel,
  AisMenuSelect,
  AisPoweredBy,
  AisStateResults,
  AisRefinementList
} from 'vue-instantsearch'
import algoliasearch from 'algoliasearch/lite'
import '@/assets/sass/_docsearch_variables.css'
import '@/assets/sass/_docsearch_modal.css'
import { mapGetters, mapActions } from "vuex"
import FeatherIcon from "@/helpers/FeatherIcon"
import ContactStatusMixin from "@/mixins/contact-status"
import _ from 'lodash'
import EmptyState from '@/dashboard/empty-state'
// import { history } from 'instantsearch.js/es/lib/routers'
// import { simple } from 'instantsearch.js/es/lib/stateMappings'
// import { state } from '~/store/activityPopup'

export default {
  name: 'AlgoliaSearch',
  mixins: [ContactStatusMixin],
  data() {
    let vm = this
    return {
      searchClient: algoliasearch(
        'QQV2EKNWGP',
        '7e99da44705473088b81127a4a5aef06'
      ),
      showSearch: false,
      hideStatusRefinements: false,
      hideLocationRefinements: false,
      initialUiState: null,
      selectedItemId: null,
      allItems: null,
      selectedItemIndex: 0,
      routing: {
        stateMapping: {
          stateToRoute: vm.stateToRoute,
          routeToState: vm.routeToState,
        },
      },
      searchFunction(helper) {
        if (vm.showSearch) {
          helper.state.facets = ['account_id']
          helper.addFacetRefinement('account_id', vm.accountId).search()
        }
        // // if (vm.filterLocations) {
        // //   helper.state.hierarchicalFacetsRefinements['locations'] = [vm.filterLocations.name]
        // // }
        return helper
      },
    }
  },
  components: {
    AisInstantSearch,
    AisRefinementList,
    AisHits,
    AisHighlight,
    AisSearchBox,
    AisMenuSelect,
    AisPagination,
    AisSnippet,
    AisStateResults,
    AisPoweredBy,
    FeatherIcon,
    AisPanel,
    AisAutocomplete,
    EmptyState,
  },
  computed: {
    ...mapGetters({
      accountId: "user/getSelectedAccountId",
      locations: "locations/getLocationsWithAddress",
      filterLocations: "leads/filterLocations",
      selectedAccount: "accounts/getSelectedAccount",
      user: "user/getUser",
    }),
  },
  async mounted() {
    window.onkeyup = function (e) {
      // if (!e) e = window.event
    }
    let data = await this.userLocations()
    this.hasFullAccess = data.hasFullAccess
    window.onkeydown = (e) => {
      // * 38 == KeyUp
      // * 40 == KeyDown
      // * 13 == Enter
      // console.log('onkeydown: ' + e.keyCode)
      if (e.keyCode == 38) {
        e.preventDefault()
        if (this.selectedItemIndex != 0) {
          this.selectedItemIndex--
          this.selectedItemId = this.allItems[this.selectedItemIndex].objectID
        }
        else {
          this.selectedItemIndex = this.allItems.length - 1
          this.selectedItemId = this.allItems[this.selectedItemIndex].objectID
        }
        document.getElementById(this.allItems[this.selectedItemIndex].objectID).scrollIntoView()
      }
      else if (e.keyCode == 40) {
        e.preventDefault()
        if (this.selectedItemIndex != this.allItems.length - 1) {
          this.selectedItemIndex++
          this.selectedItemId = this.allItems[this.selectedItemIndex].objectID
        }
        else {
          this.selectedItemIndex = 0
          this.selectedItemId = this.allItems[this.selectedItemIndex].objectID
        }
        document.getElementById(this.allItems[this.selectedItemIndex].objectID).scrollIntoView()
      }
      else if (e.keyCode == 13) {
        e.preventDefault()
        this.$router.push(`/leads/${ this.accountId }/view/${ this.allItems[this.selectedItemIndex].objectID }`)
      }
    }
  },
  methods: {
    ...mapActions({
      userLocations: "user/userLocationsAndAccess",
    }),
    toggleSearch(e) {
      this.initialUiState = null

      this.showSearch = !this.showSearch
      if (this.showSearch === true) {
        this.$nextTick(() => {
          if (this.filterLocations && this.$refs['instantSearch'] && this.$refs['instantSearch'].instantSearchInstance && this.$refs['instantSearch'].instantSearchInstance.renderState['prod_contacts'] && this.$refs['instantSearch'].instantSearchInstance.renderState['prod_contacts'].menu) {
            this.$refs['instantSearch'].instantSearchInstance.renderState['prod_contacts'].menu['locations'].refine(this.filterLocations.name)
          }
        })
        setTimeout(() => {
          // console.log(this.$refs)
          if (this.$refs['searchBox'] && this.$refs['searchBox'].$el && this.$refs['searchBox'].$el.children && this.$refs['searchBox'].$el.children[0] && this.$refs['searchBox'].$el.children[0][0]) {
            this.$refs['searchBox'].$el.children[0][0].focus()
          }
          this.sendHotjarEvent({
            email: this.user.email,
            name: this.user.name,
            account: this.selectedAccount && this.selectedAccount.name ? this.selectedAccount.name : 'Algolia Search',
            action: "Lead Manager Search",
          })
        }, 350)
      }
    },
    closeSearch(e) {
      if (e.srcElement.className !== "DocSearch-Button") {
        this.showSearch = !this.showSearch
      }

    },
    selectItem(item, index) {
      this.selectedItemIndex = index
      this.selectedItemId = item.objectID
    },
    transformLocationItems(items) {
      console.log("🚀 ~ file: AlgoliaSearch.vue ~ line 272 ~ transformLocationItems ~ items", items)
      if (items && items.length == 0 || !this.filterLocations) {
        this.hideLocationRefinements = true
        return items
      }
      else {
        this.hideLocationRefinements = false
        for (let item of items) {
          if (this.filterLocations && this.filterLocations.name == item.label) {
            return [item]
          }
        }
      }
    },
    transformStatusItems(items) {
      if (items && items.length == 0)
        this.hideStatusRefinements = true
      else
        this.hideStatusRefinements = false
      return items
    },
    transformItems(items) {
      this.allItems = _.cloneDeep(items)
      if (items[0]) {
        this.selectedItemId = items[0].objectID
        this.selectedItemIndex = 0
      }
      else {
        this.selectedItemId = null
        this.selectedItemIndex = null
      }
      return items
    }
  }
}
