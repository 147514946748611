
import { mapGetters, mapMutations } from "vuex"
import PugImg from "@/dashboard/pug-img"
import InsightsSidenavProfile from "./InsightsSidenavProfile"
// import tippy from "tippy.js"
// import "tippy.js/dist/tippy.css"
// import "tippy.js/animations/scale.css"

export default {
  components: {
    PugImg,
    InsightsSidenavProfile,
  },
}
