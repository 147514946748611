
import { mapGetters, mapMutations } from "vuex"
import PugImg from "@/dashboard/pug-img"
import InsightsSidenavProfile from "./InsightsSidenavProfile"
// import tippy from "tippy.js"
// import "tippy.js/dist/tippy.css"
// import "tippy.js/animations/scale.css"
import { createPopper } from "@popperjs/core"

export default {
  components: {
    PugImg,
    InsightsSidenavProfile,
  },
  data() {
    return {
      popper: null,
      profilePopper: null,
      profile: null,
      previousTimeout: null,
      visibleMenuId: null,
      visibleProfileMenuId: null,
      isFocused: true,
    }
  },
  props: {
    shouldHide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      sidebarMenu: "navigation/getSidebarMenu",
      selectedWebsite: "website/getSelectedWebsite",
      selectedAccount: "accounts/getSelectedAccount",
      unitCacheStatus: "cache/getUnitCacheStatus",
    }),
  },

  mounted() {
    this.$nextTick(() => {
      window.onfocus = this.onFocus
      window.onblur = this.onBlur
      document.addEventListener("mouseleave", (event) => {
        if (event.clientY <= 0 || event.clientX <= 0 || event.clientX >= window.innerWidth || event.clientY >= window.innerHeight) {
          if (this.popper) {
            this.popper.destroy()
            this.popper = null
            this.visibleMenuId = null
          }
        }
      })
    })
  },
  methods: {
    onFocus() {
      this.isFocused = true
    },
    onBlur() {
      this.isFocused = false
      this.hideSubmenu()
    },
    onLogoClick() {
      if (this.selectedAccount && this.selectedAccount.id) {
        this.$router.push(`/home/${ this.selectedAccount.id }`)
      }
      else {
        this.$router.push(`/accounts`)

      }
    },
    isActiveParent(children) {
      if (this.$route && this.$route.path) {
        const matchingRoute = children.find((c) => this.$route.path.startsWith(c.path))
        if (matchingRoute !== undefined) {
          return true
        }
      }
      return false
    },
    isActive(path) {
      if (this.$route && this.$route.path && this.$route.path === path) {
        return true
      } else return false
    },
    eventClicked(e) {
      if (e === "update_cache") {
        clearTimeout(this.previousTimeout)
        this.previousTimeout = setTimeout(() => {
          this.updateCache()
        }, 200)
      } else if (e === "copy_client_website") {
        this.$bvModal.show("importer-modal")
      }
    },
    async updateCache() {
      try {
        await SitelinkAPI.updateCache(this.selectedAccount.id)
        this.updateUnitCacheStatus({
          account_id: this.selectedAccount.id,
          status: "started",
        })
        this.toastInfo("Cache Update Started")
      } catch (e) {
        this.$rollbar.error('Error starting unit cache', e, { account_id: this.selectedAccount.id })
      }
    },

    onMenuItemClicked(event) {
      this.clickResults = event
      if (event.action === "logout") {
        this.setUser(null)
        this.$store.dispatch('user/setCurrentFcmToken', null)
        this.delete_cookie("pugg_dash")
        this.$storage.remove("user")
        firebase.auth().signOut()
        this.$router.push("/login")
      }
    },

    hideSubmenu() {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
        this.visibleMenuId = null
      }
    },
    showSubmenu(id, showMenu = true) {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
        this.visibleMenuId = null
      }
      if (showMenu === true && this.isFocused) {
        this.visibleMenuId = id
        let tooltip = document.getElementById(`submenu--${ id }`)
        let el = document.getElementById(`matty--${ id }`)
        const arrow = document.getElementById(`matty--arrow-${ id }`)
        this.popper = createPopper(el, tooltip, {
          placement: "right-start",
          modifiers: [
            {
              name: "arrow",
              options: {
                element: arrow,
              },
            },
            {
              name: "offset",
              options: {
                offset: [-18, 5],
              },
            },
          ],
        })
      }
    },
  },
}
