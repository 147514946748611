import Sidebar from "@/dashboard/sidebar/sidebar-potato"
import SidebarItem from "@/dashboard/sidebar/base-sidebar-item"

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: false,
  breakpoint: 992,
  hovered: false,
  displaySidebar(value) {
    if (window.innerWidth > this.breakpoint) {
      return
    }
    this.isMinimized = !value
    this.showSidebar = value
    let docClasses = document.body.classList
    if (value) {
      docClasses.add("g-sidenav-pinned")
      docClasses.add("g-sidenav-show")
      docClasses.remove("g-sidenav-hidden")
    } else {
      docClasses.add("g-sidenav-hidden")
      docClasses.remove("g-sidenav-pinned")
      docClasses.remove("g-sidenav-show")
    }
  },
  toggleMinimize() {
    let docClasses = document.body.classList
    this.isMinimized = !this.isMinimized
    if (this.isMinimized) {
      docClasses.add("g-sidenav-hidden")
      docClasses.remove("g-sidenav-pinned")
      docClasses.remove("g-sidenav-show")
    } else {
      docClasses.add("g-sidenav-pinned")
      docClasses.add("g-sidenav-show")
      docClasses.remove("g-sidenav-hidden")
    }
    if (this.hovered) {
      docClasses.add("g-sidenav-show")
    }
  },
  onMouseEnter() {
    this.hovered = true
    if (this.isMinimized) {
      // document.body.classList.add("g-sidenav-show")
      // document.body.classList.remove("g-sidenav-hidden")
    }
  },
  onMouseLeave() {
    this.hovered = false
    if (this.isMinimized) {
      const docClasses = document.body.classList
      docClasses.remove("g-sidenav-show")
      docClasses.add("g-sidenav-hide")
      setTimeout(() => {
        docClasses.remove("g-sidenav-hide")
        docClasses.add("g-sidenav-hidden")
      }, 300)
    }
  }
}

const SidebarPlugin = {
  install(Vue, options) {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks
    }
    const app = new Vue({
      data: {
        sidebarStore: SidebarStore
      }
    })
    Vue.prototype.$sidebar = app.sidebarStore
    Vue.component("ui-side-bar", Sidebar)
    Vue.component("ui-sidebar-item", SidebarItem)
  }
}

export default SidebarPlugin
