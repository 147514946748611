import { render, staticRenderFns } from "./BaseSidePanel.vue?vue&type=template&id=51f0ab2c&lang=pug"
import script from "./BaseSidePanel.vue?vue&type=script&lang=js"
export * from "./BaseSidePanel.vue?vue&type=script&lang=js"
import style0 from "./BaseSidePanel.vue?vue&type=style&index=0&id=51f0ab2c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeatherIcon: require('/helpers/FeatherIcon.vue').default,PugButton: require('/dashboard/buttons/pug-button/PugButton.vue').default,CardWithImage: require('/dashboard/cards/card-with-image/CardWithImage.vue').default})
