
import CardWithImage from "@/dashboard/cards/card-with-image"
import FeatherIcon from "@/helpers/FeatherIcon"
import PugButton from '@/dashboard/buttons/pug-button'
import draggable from "vuedraggable"
import * as _ from "lodash"
import Search from "@/dashboard/search/sandwich-search"

export default {
  name: "BaseSidePanel",
  components: {
    draggable,
    CardWithImage,
    FeatherIcon,
    Search,
    PugButton
  },
  props: {
    vBToggleName: {
      type: String,
      default: "BaseSidePanel"
    },
    showCogEditButton: {
      type: Boolean,
      default: false
    },
    showLocationFilter: {
      type: Boolean,
      default: true
    },
    /**
     * Used for showing if components are a location component in the editor.
     */
    showComponentLocationFilter: {
      type: Boolean,
      default: false
    },
    cards: {
      type: [Array, Object],
      required: true, default: () => []
    },
    title: {
      type: String,
      default: "Add Component"
    },
    canHide: {
      type: Boolean,
      default: true
    },
    sideBarFull: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: "Search"
    },
  },
  data() {
    return {
      itemsSearch: null,
      itemsArray: [],
      showBaseSidePanel: true,
      visible: {},
      filter: 'single',
      searchResults: {},
      noSearchResults: false,
    }
  },
  watch: {
    cards: {
      handler() {
        if (this.cards) {
          let count = 0
          let searchItems = []
          if (this.showComponentLocationFilter == true) {
            this.filter = 'all'
          }
          for (let id in this.cards) {
            let item = this.cards[id]
            //if we ever want to have the first section open, just uncomment this.
            // if (count === 0) {
            //   this.visible['collapse--' + count] = true
            // } else {
            //   this.visible['collapse--' + count] = false
            // }
            // count++
            searchItems = searchItems.concat(item)
          }

          this.itemsSearch = searchItems
        }
      },
      immediate: true
    }
  },
  async mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    showSidebar() {
      this.showBaseSidePanel = true
      this.$emit('showSidebar')
    },
    clone(field) {
      return _.cloneDeep(field)
    },
    toUppercase(str) {
      return _.upperFirst(str)
    },
    hide() {
      this.$emit('onHide')
    },
    clearSearchAndCollapse() {
      let cleared = _.mapValues(this.visible, (value, key) => {
        return false
      })
      this.visible = cleared
    },
    onSettingsButtonClick(item) {
      this.$emit('onSettingsButtonClick', item)
    },
    setFilter(filter) {
      this.filter = filter
      this.$emit('setFilter', filter)
      this.$forceUpdate()
    },
    toggleGroup(id) {
      let visible = _.cloneDeep(this.visible)
      visible['collapse--' + id] = !visible['collapse--' + id]
      this.visible = Object.assign({}, this.visible, visible)
      this.$emit('toggleGroup', { id: id, visible: this.visible })
    },
    /*
    ** search items
    */
    handleSelected: _.debounce(function (selected, searchInput) {
      let keys = _.uniq(_.map(selected, 'id'))
      this.searchResults = {}
      if (selected !== null && selected.length > 0) {
        let count = 0
        for (let id in this.cards) {
          this.visible['collapse--' + count] = true
          count++
        }
        this.searchResults = _.cloneDeep(this.convertArrayToObject(keys))
        this.noSearchResults = false
        this.$emit('handleSelected')
      } else if (selected === null && searchInput) {
        this.searchResults = {}
        this.noSearchResults = true
      } else {
        this.clearSearchAndCollapse()
        this.searchResults = {}
        this.noSearchResults = false
      }
    }, 200),
    convertArrayToObject(array) {
      const initialValue = {}
      for (let item of array) {
        initialValue[item] = true
      }
      return initialValue
    },
    onCardClicked(data) {
      /**
       * Event triggered when clicking on an component inside the sidebar
       * it is sent along with the component that triggered it
       *
       * @event onCardClicked
       * @type {object}
       */
      this.$emit("onCardClicked", data)
    },
    shouldShowHeading(items, key) {
      var shouldShow = false
      for (let id in items) {
        let item = items[id]
        if (this.showLocationFilter) {
          if (!this.noSearchResults && (Object.keys(this.searchResults).length == 0 || this.searchResults[item.id]) && (this.filter === 'single' && item.is_single_location)) {
            shouldShow = true
            break
          }
          if (!this.noSearchResults && (Object.keys(this.searchResults).length == 0 || this.searchResults[item.id]) && (this.filter === 'multi' && item.is_multilocation)) {
            shouldShow = true
            break
          }
        } else if (this.showComponentLocationFilter) {
          if (!this.noSearchResults && (Object.keys(this.searchResults).length == 0 || this.searchResults[item.id]) && (this.filter === 'location' && item.is_location)) {
            shouldShow = true
            break
          }
          if (!this.noSearchResults && (Object.keys(this.searchResults).length == 0 || this.searchResults[item.id]) && (this.filter === 'all')) {
            shouldShow = true
            break
          }
        } else {
          //removing for now, may need to adjust though
          // if (!this.noSearchResults && Object.keys(this.searchResults).length == 0 || this.searchResults[item.id]) {
          //   shouldShow = true
          //   break
          // }
        }
      }
      return shouldShow
    }
  }
}
