
import { mapGetters, mapMutations } from "vuex"
import ProfileMixin from "./profile-mixin"
import isEmpty from "lodash/isEmpty"

export default {
  name: "InsightsMobilenav",
  mixins: [ProfileMixin],
  data() {
    return {
      visible: false,
      previousTimeout: null,
      activeMenuSidebar: null,
      activeProfileSubmenu: null,
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      sidebarMenu: "navigation/getSidebarMobileMenu",
      sidebarMenuSidebar: "navigation/getSidebarMobileMenuSidebar",
      selectedWebsite: "website/getSelectedWebsite",
      selectedAccount: "accounts/getSelectedAccount",
      unitCacheStatus: "cache/getUnitCacheStatus",
      userInitials: "user/getUserInitials",
    }),
    emptySelectedAccount() {
      return isEmpty(this.selectedAccount)
    },
  },
  methods: {
    isActiveParent(children) {
      if (this.$route && this.$route.path) {
        const matchingRoute = children.find((c) => this.$route.path.startsWith(c.path))
        if (matchingRoute !== undefined) {
          return true
        }
      }
      return false
    },
    isShowSidebar(index) {
      return index === this.activeMenuSidebar
    },
    showSidebar(index) {
      if (this.activeMenuSidebar === index) this.activeMenuSidebar = null
      else this.activeMenuSidebar = index
    },
    showProfileSubmenu(index, ctx) {
      if (ctx && ctx.type == "event") {
        if (ctx.event_name === "copy_client_website") {
          this.$root.$emit("bv::show::modal", "importer-modal")
        } else if (ctx.event_name == "color") {
          this.$root.$emit("bv::toggle::modal", "website-color-palette")
        } else if (ctx.event_name == "keyboard") {
          this.$root.$emit("bv::toggle::modal", "keyboard-shortcuts")
        } else if (ctx.event_name === "logout") {
          this.setUser(null)
          this.delete_cookie("pugg_dash")
          this.$storage.remove("user")
          firebase.auth().signOut()
          this.$store.dispatch('user/setCurrentFcmToken', null)
          this.$router.push("/login")
        }
        this.visible = false
      } else {
        if (this.activeProfileSubmenu === index) this.activeProfileSubmenu = null
        else this.activeProfileSubmenu = index
      }
    },
    isActive(path) {
      if (this.$route && this.$route.path && this.$route.path === path) {
        return true
      } else return false
    },
    showSubmenu() {
      this.visible = !this.visible
    },
    isActive(path) {
      if (this.$route && this.$route.path && this.$route.path === path) {
        return true
      } else return false
    },
    eventClicked(e) {
      if (e.event_name === "update_cache") {
        clearTimeout(this.previousTimeout)
        this.previousTimeout = setTimeout(() => {
          this.updateCache()
        }, 200)
      } else if (e.event_name === "copy_client_website") {
        this.$bvModal.show("importer-modal")
      } else if (e.event_name == "color") {
        this.$root.$emit("bv::toggle::modal", "website-color-palette")
      } else if (e.event_name == "keyboard") {
        this.$root.$emit("bv::toggle::modal", "keyboard-shortcuts")
      } else if (e.event_name === "logout") {
        this.setUser(null)
        this.delete_cookie("pugg_dash")
        this.$storage.remove("user")
        this.$store.dispatch('user/setCurrentFcmToken', null)
        firebase.auth().signOut()
        this.$router.push("/login")
      }
      this.visible = false
    },
  },
}
