import { render, staticRenderFns } from "./InsightsSidenav.vue?vue&type=template&id=1711b7ee&lang=pug"
import script from "./InsightsSidenav.vue?vue&type=script&lang=js"
export * from "./InsightsSidenav.vue?vue&type=script&lang=js"
import style0 from "./InsightsSidenav.vue?vue&type=style&index=0&id=1711b7ee&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PugImg: require('/dashboard/pug-img/PugImg.vue').default,InsightsSidenavProfile: require('/dashboard/sidebar/InsightsSidenavProfile.vue').default})
