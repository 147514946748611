
import { createPopper } from "@popperjs/core"
import { mapActions, mapGetters } from "vuex"
import ProfileMixin from "./profile-mixin"

export default {
  mixins: [ProfileMixin],
  data() {
    return {
      popper: null,
      profilePopper: null,
      visibleProfileMenuId: null,
      profile: null,
      isShowProfile: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      userInitials: "user/getUserInitials",
    }),
  },

  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    showProfile() {
      if (this.isShowProfile === false) {
        let tooltip = document.getElementById(`insights-profile`)
        let el = document.getElementById(`matty-profile`)
        this.isShowProfile = true
        this.profile = createPopper(el, tooltip, {
          placement: "right",
          strategy: "fixed",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-140, 85],
              },
            },
            {
              name: "preventOverflow",
              options: {
                mainAxis: true,
              },
            },
          ],
        })
      } else {
        this.hideProfile()
      }
    },
    delete_cookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    },
    showProfileSubmenu(id, showMenu = true) {
      if (this.profilePopper) {
        this.profilePopper.destroy()
        this.profilePopper = null
        this.visibleProfileMenuId = null
      }
      if (showMenu === true) {
        this.visibleProfileMenuId = id
        let tooltip = document.getElementById(`profile-submenu--${ id }`)
        let el = document.getElementById(`profile-matty--${ id }`)
        this.profilePopper = createPopper(el, tooltip, {
          placement: "right",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [20, 275],
              },
            },
          ],
        })
      }
    },
    hideProfile() {
      if (this.profile) {
        this.profile.destroy()
        this.profile = null
        this.isShowProfile = false
      }
    },
  },
}
