
import { ApiFactory } from "@/utils/apis/ApiFactory"
const LocationApi = ApiFactory.get("location")
import { mapGetters, mapActions, mapMutations } from "vuex"
import Multiselect from "vue-multiselect"
import flatPickr from "vue-flatpickr-component"
import { startOfToday, subDays, endOfToday, startOfMonth, subMonths, lastDayOfMonth, startOfYear, lastDayOfWeek, subWeeks, startOfWeek, endOfWeek, subYears, endOfYear, startOfDay, endOfDay } from "date-fns"
import _ from "lodash"
import { generateApiUrl } from "@/utils/helpers"
import PugImg from "@/dashboard/pug-img"

/**
 * A card to display statistical data at a quick glimpse.
 * 
 */
export default {
  name: "StatsDateFilter",
  components: {
    flatPickr,
    Multiselect,
    PugImg,
  },
  props: {
    /**
     * This is used for the location Home pages to automatically filter for 1 location
     */
    location_id: {
      type: String,
    },
    hideLocation: {
      type: Boolean,
      default: false,
    },
    hideDates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date_filters: [
        { name: "Today", value: "today" },
        { name: "This week", value: "this_week" },
        { name: "Last 7 days", value: "last_7" },
        { name: "Last week", value: "last_week" },
        { name: "Last 30 Days", value: "last_30" },
        { name: "This month", value: "this_month", default: true },
        { name: "Last month", value: "last_month" },
        { name: "Last 90 days", value: "last_90" },
        { name: "This year", value: "this_year" },
        { name: "Last 365 Days", value: "last_365" },
        { name: "Last year", value: "last_year" },
      ],
      selected_date_filter: null,
      startDate: null,
      endDate: null,
      previousTimeout: null,
      accounts: [],
      filteredAccounts: [],
      noChange: false,
      noChangeEnd: false,
      isDraggableValue: false,
      configs: {
        start: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "F j, Y",
          altInput: true,
          dateFormat: "Y-m-d",
          maxDate: endOfToday(),
        },
        end: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "F j, Y",
          altInput: true,
          dateFormat: "Y-m-d",
          maxDate: endOfToday(),
          minDate: null,
        },
      },
      locationFilters: null,
    }
  },
  computed: {
    ...mapGetters({
      layoutVars: "layoutVars/getLayout",
      selectedWebsite: "website/getSelectedWebsite",
      dateFrom: "charts/getDateFrom",
      parentDashboard: "charts/getParentDashboard",
      isAdmin: "charts/getIsAdminCharts",
      dateTo: "charts/getDateTo",
      user: "user/getUser",
      getLocations: "locations/getLocations",
      accountId: "user/getSelectedAccountId",
      filteredLocations: "charts/filterLocations",
      currentAuthToken: "user/getCurrentAuthToken",
      selectedAccount: "accounts/getSelectedAccount",
      rhsClientCodes: "accounts/getRhsClientCodes",
    }),
    showAccountFilter() {
      return this.isAdmin === true &&
        this.accounts.length > 1 &&
        !this.hideLocation
    },
    showLocationFilter() {
      return !this.location_id &&
        !this.hideLocation &&
        this.isAdmin === false &&
        this.getLocations.length > 1 &&
        this.parentDashboard &&
        this.parentDashboard.show_location_filter === true &&
        this.selectedWebsite.is_website_live === true
    },
  },
  created() {
    this.startDate = _.clone(this.dateFrom)
    this.endDate = _.clone(this.dateTo)
    this.isDraggableValue = this.isDraggable
    if (this.isAdmin === true) {
      this.loadAccounts()
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.selected_date_filter = this.date_filters.find(date_filter => date_filter.default)
      this.setFilteredLocations(null)

      axios.defaults.headers.common['Authorization'] = this.currentAuthToken
      try {
        const relations = "address"
        if (this.getLocations.length <= 0 || (this.getLocations.length && this.getLocations[0].account_id !== this.accountId)) {
          const response = await LocationApi.all(this.accountId, relations)
          const locations = response.data.data

          this.setLocations(locations)
        } else {
          this.locations = this.getLocations
        }
      } catch (e) {
        this.$rollbar.error('Error setting locations for stats date filter', e, { account_id: this.accountId })
        this.isError = true
        this.toastError("Error loading locations")
      }
    })
  },
  watch: {
    locationFilters() {
      // const locationIds = this.locationFilters && this.locationFilters.id ? this.locationFilters.id : null
      let isRhs = _.findIndex(this.rhsClientCodes, (o) => { return o == this.selectedAccount.client_code })
      if (isRhs == -1) {
        this.setFilteredLocations(this.locationFilters)
      }
      this.toggleDateChange()
    },
    filteredAccounts() {
      let filters = []
      for (let loc of this.filteredAccounts) {
        filters.push(loc.client_code)
      }
      this.setFilteredClients(filters)
      this.toggleDateChange()
    },
    selected_date_filter() {
      if (this.selected_date_filter && this.$refs.startDate && this.$refs.endDate) {
        this.setDates(this.selected_date_filter.value)
      }
    },
    isDraggable() {
      this.isDraggableValue = this.isDraggable
    },
  },
  methods: {
    ...mapActions({
      toggleUpdateDates: "charts/toggleUpdateDates",
      setDateTo: "charts/setDateTo",
      setDateFrom: "charts/setDateFrom",
      setFilteredClients: "charts/setFilteredClients",
      setLocations: "locations/setLocations",
      setFilteredLocations: "charts/setFilteredLocations",
    }),

    async loadAccounts() {
      try {
        const endpoint = "account/reference"
        const url = generateApiUrl(`${ endpoint }`)
        let { data: res } = await axios.get(url, { headers: { "User-ID": this.user.id, "x-purge": true } })
        this.accounts = res.data
      } catch (e) { }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.$set(this.configs.end, "minDate", dateStr)
      this.setDateFrom(new Date(dateStr))

      // this.$refs.endDate.$el.click()
      this.toggleDateChange()
      if (this.noChange === false) {
        setTimeout(() => {
          this.$refs.endDate.fp.open()
        }, 100)
      } else {
        this.noChange = false
      }
    },
    buildLogo(account) {
      let logo = {
        alt: account.logo_alt,
        counter: account.logo_counter,
        file_base_url: account.logo_file_base_url,
        file_name: account.logo_file_name,
        file_name_full: account.logo_file_name_full,
        file_name_original: account.logo_file_name_original,
        file_name_original_unmodified: account.logo_file_name_original_unmodified,
        file_name_webp: account.logo_file_name_webp,
        file_type: account.logo_file_type,
        is_global: account.logo_is_global === 1 ? true : false,
        is_icon: account.logo_is_icon === 1 ? true : false,
        is_stock_photo: account.logo_is_stock_photo === 1 ? true : false,
        src: account.logo_src,
        src_webp: account.logo_src_webp,
        title: account.logo_title,
        type: account.logo_type,
      }
      return logo
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.$set(this.configs.start, "maxDate", dateStr)
      // console.log('onEndChange', dateStr)
      this.setDateTo(new Date(dateStr))
      this.toggleDateChange()
    },
    toggleDateChange() {
      clearTimeout(this.previousTimeout)
      this.previousTimeout = setTimeout(() => {
        this.toggleUpdateDates()
      }, 2000)
    },
    setDates(type) {
      if (type === "today") {
        this.$refs.startDate.fp.setDate(startOfToday())
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfToday())
        this.setDateTo(startOfToday())
      } else if (type === "last_7") {
        this.$refs.startDate.fp.setDate(startOfDay(subDays(new Date(), 7)))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfDay(subDays(new Date(), 7)))
        this.setDateTo(startOfToday())
      } else if (type === "last_30") {
        this.$refs.startDate.fp.setDate(startOfDay(subDays(new Date(), 30)))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfDay(subDays(new Date(), 30)))
        this.setDateTo(startOfToday())
      } else if (type === "last_90") {
        this.$refs.startDate.fp.setDate(startOfDay(subDays(new Date(), 90)))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfDay(subDays(new Date(), 90)))
        this.setDateTo(startOfToday())
      } else if (type === "last_365") {
        this.$refs.startDate.fp.setDate(startOfDay(subDays(new Date(), 365)))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfDay(subDays(new Date(), 365)))
        this.setDateTo(startOfToday())
      } else if (type === "this_month") {
        this.$refs.startDate.fp.setDate(startOfMonth(new Date()))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfMonth(new Date()))
        this.setDateTo(startOfToday())
      } else if (type === "last_month") {
        this.$refs.startDate.fp.setDate(startOfMonth(subMonths(new Date(), 1)))
        this.$refs.endDate.fp.setDate(lastDayOfMonth(subMonths(new Date(), 1)))
        this.setDateFrom(startOfMonth(subMonths(new Date(), 1)))
        this.setDateTo(lastDayOfMonth(subMonths(new Date(), 1)))
      } else if (type === "this_year") {
        this.$refs.startDate.fp.setDate(startOfYear(new Date()))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfYear(new Date()))
        this.setDateTo(startOfToday())
      } else if (type === "last_year") {
        this.$refs.startDate.fp.setDate(startOfDay(startOfYear(subYears(new Date(), 1))))
        this.$refs.endDate.fp.setDate(startOfDay(endOfYear(subYears(new Date(), 1))))
        this.setDateFrom(startOfDay(startOfYear(subYears(new Date(), 1))))
        this.setDateTo(startOfDay(endOfYear(subYears(new Date(), 1))))
      } else if (type === "this_week") {
        this.$refs.startDate.fp.setDate(startOfWeek(new Date()))
        this.$refs.endDate.fp.setDate(startOfToday())
        this.setDateFrom(startOfWeek(new Date()))
        this.setDateTo(startOfToday())
      } else if (type === "last_week") {
        this.$refs.startDate.fp.setDate(startOfWeek(subWeeks(new Date(), 1)))
        this.$refs.endDate.fp.setDate(endOfWeek(subWeeks(new Date(), 1)))
        this.setDateFrom(startOfWeek(subWeeks(new Date(), 1)))
        this.setDateTo(endOfWeek(subWeeks(new Date(), 1)))
      }

      this.noChange = true
      this.noChangeEnd = true
      this.toggleUpdateDates()
    },
  },
}
