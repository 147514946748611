
import UiSidebarItem from "@/dashboard/sidebar/base-sidebar-item"
import { mapGetters, mapMutations } from "vuex"
import PugImg from "@/dashboard/pug-img"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const SitelinkAPI = ApiFactory.get("sitelink")
const ImporterApi = ApiFactory.get("importer")
// import { config } from "@/config"
// import { PubSub } from 'aws-amplify'

/**
 * The sidebar component is perfect for vertical navigations. By default
 * the sidebar is fixed on the left of the window. We can customize the
 * positioning, branding, and color of the sidebar.
 *
 */
export default {
  name: "SidebarPotato",
  components: {
    UiSidebarItem,
    PugImg,
  },
  props: {
    /**
     * Sidebar title.
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * The logo prop controls the branding logo at the top of the sidebar.
     *
     * This is typically the app logo.
     */
    logo: {
      type: String,
      default: null,
    },
    /**
     * The alt text prop handles the alt text for the logo.
     */
    logoAlt: {
      type: String,
      default: null,
    },
    /**
     * The hide logo prop controls whether to hide the logo. We allow this
     * in case we want to have a text title instead of a logo. When set to
     * `true` the title text will be visible.
     */
    hideLogo: {
      type: Boolean,
      default: null,
    },
    /**
     * Base href prop determines the route the user will go to when
     * the sidebar logo or title are clicked.
     */
    baseHref: {
      type: String,
      default: "#",
    },
    showSidebarLinks: {
      type: Boolean,
      default: false,
    },
    /**
     * Sidebar position prop controls the positioning of the sidebar.
     *
     * Default is `fixed-left`. Values can be (undefined|fixed-left|fixed-right)
     */
    sidebarPosition: {
      type: String,
      default: "fixed-left",
      validator: (value) => {
        return ["", "fixed-left", "fixed-right"].includes(value)
      },
    },
    /**
     * The background color prop controls the background color
     * of the sidebar.
     *
     * Default is `white` and can be set to any or any gradient variety of the following:
     * (undefined|light|primary|secondary|warning|dark|info|danger|success)
     */
    backgroundColor: {
      type: String,
      default: "white",
    },
    /**
     * The sidebar type prop controls the text color of the sidebar. Use
     * light for light background colors or dark for dark background colors.
     *
     * Default is `light`
     */
    sidebarType: {
      type: String,
      default: "light",
      validator: (value) => {
        return ["", "light", "dark"].includes(value)
      },
    },
    /**
     * Sidebar breakpoint controls the responsive nature for collapsing menus
     * and the color scheme classes.
     */
    sidebarBreakpoint: {
      type: String,
      default: "xs",
    },
    /**
     * Toggle breakpoint controls when to hide the toggle button. Once hidden the toggle
     * event can be handled in an external component
     *
     * Default is 'lg'
     */
    toggleBreakpoint: {
      type: String,
      default: "lg",
    },
    /**
     * Whether sidebar should autoclose on mobile when clicking an item
     */
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formSettings: [],
      isAdvanced: false,
      /**
       * Please do not change these values, unless you change the same values in the
       * import-client controller on `pizza-api`.
       */
      checklistSelected: ["account", "locations", "websites", "email_notifications", "media", "nav_menus"],
      checklistOptions: [
        { text: "Account", value: "account" },
        { text: "Locations", value: "locations" },
        { text: "Websites", value: "websites" },
        { text: "Email Notifications", value: "email_notifications" },
        { text: "Media", value: "media" },
        { text: "Nav Menus", value: "nav_menus" },
      ],
      isLoading: false,
      importErrors: null,
      previousTimeout: null,
      toEnvironment: null,
      toOptions: [
        { value: null, text: "Please select an environment" },
        { value: "staging", text: "Copy to staging" },
        { value: "prod", text: "Copy to production" },
      ],
    }
  },
  watch: {
    $route: "onRouteChange",
  },
  mounted() {
    /**
     * We check the sidebar instance property. If the sidebar instance and
     * the showSidebar flag exists, we add global styles to the body tag.
     *
     * This is a fallback for an app that may not have an index.html or
     * App.vue file, where we would normally set the body classes.
     *
     * Instance Properties can be read about here:
     * - https://vuejs.org/v2/cookbook/adding-instance-properties.html
     */
    this.onRouteChange()
  },
  computed: {
    ...mapGetters({
      sidebarMenu: "navigation/getSidebarMenu",
      selectedWebsite: "website/getSelectedWebsite",
      selectedAccount: "accounts/getSelectedAccount",
      unitCacheStatus: "cache/getUnitCacheStatus",
    }),
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  /**
   * Hide sidebar on destroy
   */
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  // destroyed() {
  //   this.subSuccess.unsubscribe()
  //   this.subError.unsubscribe()
  // },
  methods: {
    ...mapMutations({
      updateUnitCacheStatus: "cache/updateUnitCacheStatus",
    }),
    onRouteChange() {
      this.$nextTick(() => {
        this.updateSidebar()
      })
    },
    updateSidebar() {
      this.$sidebar.isMinimized = this.$sidebar.breakpoint > window.innerWidth
      if (this.$sidebar.breakpoint < window.innerWidth) {
        this.$sidebar.showSidebar = true
        setTimeout(() => {
          let body = document.getElementById("pug-body")
          body.classList.add("g-sidenav-pinned")
          body.classList.add("g-sidenav-show")
        }, 150)
      } else {
        setTimeout(() => {
          let body = document.getElementById("pug-body")
          this.$sidebar.showSidebar = false
          body.classList.remove("g-sidenav-pinned")
          body.classList.remove("g-sidenav-show")
          body.classList.add("g-sidenav-hidden")
        }, 15)
      }
    },
    /**
     * Toggle sidebar state
     */
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
    eventClicked(e) {
      if (e === "update_cache") {
        clearTimeout(this.previousTimeout)
        this.previousTimeout = setTimeout(() => {
          this.updateCache()
        }, 200)
      } else if (e === "copy_client_website") {
        this.$bvModal.show("importer-modal")
      }
    },
    async updateCache() {
      try {
        await SitelinkAPI.updateCache(this.selectedAccount.id)
        this.updateUnitCacheStatus({
          account_id: this.selectedAccount.id,
          status: "started",
        })
        this.toastInfo("Cache Update Started")
      } catch (e) {
        this.$rollbar.error('SidebarPotato: Error updating cache', e, { account_id: this.selectedAccount.id })
      }
    },
    /**
     * Import client based on modal settings
     *
     * @todo
     * - Add options to select portions of account we want to import
     */
    async onImportClient(event) {
      this.isLoading = true
      try {
        // Create checklist object
        const checklist = {}
        for (let item of this.checklistOptions) {
          const index = this.checklistSelected.findIndex((i) => i == item.value)
          if (index > -1) {
            checklist[item.value] = true
          } else {
            checklist[item.value] = false
          }
        }

        // Import to specific environment
        if (this.toEnvironment === "stage" || this.toEnvironment === "staging") {
          const staging_endpoint = `https://api.storpug.com/${this.$config.api_version}`
          const { data: response } = await ImporterApi.importClient(this.selectedAccount, staging_endpoint, checklist)
        } else if (this.toEnvironment === "prod" || this.toEnvironment === "production") {
          const prod_endpoint = `https://api.storagepug.app/${this.$config.api_version}`
          const { data: response } = await ImporterApi.importClient(this.selectedAccount, prod_endpoint, checklist)
        }

        this.importErrors = null

        this.isLoading = false
        // Close modal
        this.$bvModal.hide("importer-modal")
      } catch (error) {
        this.isLoading = false
        this.importErrors = error
        this.toastError("Error importing client.")
      }
    },
    /**
     * Reset importer form models
     */
    onImporterModalHidden() {
      this.isAdvanced = false
      this.checklistSelected = ["account", "locations", "websites", "email_notifications", "media", "nav_menus"]
      this.importErrors = null
      this.toEnvironment = null
    },
    /**
     * Listen to any importer web socket
     */
    // listenForWebsocket() {
    //   this.subSuccess = PubSub.subscribe('import_client_success').subscribe({
    //     next: (data) => {
    //       console.log('Message received importer', data)
    //       this.toastSuccess('Successfully imported client!')
    //     },
    //     error: (error) => {
    //       console.error('Success Importer Error: ', error)
    //       this.$rollbar.error(error)
    //     },
    //     close: () => console.log('Done')
    //   })
    //   this.subError = PubSub.subscribe('import_client_error').subscribe({
    //     next: (data) => {
    //       console.log('Message received importer', data)
    //     },
    //     error: (error) => {
    //       console.error('Error Importer Error: ', error)
    //       this.$rollbar.error(error)
    //     },
    //     close: () => console.log('Done')
    //   })
    // }
  },
}
