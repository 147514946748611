import * as _ from 'lodash'
import * as uuid from 'uuid/v1'

export const generateForm = (component) => {
  let config = {}
  switch (component.name.toLowerCase()) {
    case 'text':
      config = generateTextConfig()
      break
    case 'image':
      config = generateImageConfig()
      break
    case 'row':
      config = generateRowConfig()
      break
    default:
      break
  }

  return config
}

const generateTextConfig = () => {
  return {
    name: 'Text Component',
    formGroups: [
      {
        name: 'text',
        label: 'Text',
        settings: [
          {
            type: 'text',
            name: 'text_content',
            label: 'Text content',
            default: '',
            description: 'Add the text you would like to see displayed in this component'
          }
        ]
      },
      { ...customCssConfig }
    ]
  }
}

const generateRowConfig = () => {
  return {
    name: 'Row',
    formGroups: [
      {
        name: 'row',
        label: 'Row',
        settings: [
          {
            type: 'text',
            name: 'row_name',
            label: 'Row name',
            default: '',
            description: 'Give this row a name'
          }
        ]
      },
      { ...customCssConfig }
    ]
  }
}

const generateImageConfig = () => {
  return {
    name: 'Image Component',
    formGroups: [
      {
        name: 'image',
        label: 'Image',
        settings: [
          {
            type: 'text',
            name: 'image_url',
            label: 'Image URL',
            default: '',
            description: 'Paste your image URL'
          },
          {
            type: 'text',
            name: 'image_alt',
            label: 'Image alt text',
            default: '',
            description: 'Your image alt text'
          }
        ]
      },
      { ...customCssConfig }
    ]
  }
}

const customCssConfig = {
  name: 'custom',
  label: 'Custom CSS',
  settings: [
    {
      type: 'text',
      name: 'styles_inline',
      label: 'Inline Styles'
    },
    {
      type: 'text',
      name: 'css_classes',
      label: 'CSS Classes'
    }
  ]
}

export const buildPropSchemas = (extendOptions, stringifyMapper) => {
  // If component has a mixin, merge mixin props with custom props
  let mixinProps = {}
  if (extendOptions.mixins) {
    for (const mixin of extendOptions.mixins) {
      if (mixin && mixin.props) {
        mixinProps = { ...mixinProps, ...mixin.props }
      }
    }
  }

  const extendProps = extendOptions.props
  const mergedProps = { ...mixinProps, ...extendProps }

  // Build Prop schemas
  const propSchemas = []
  for (const prop in mergedProps) {
    const propObj = mergedProps[prop]
    let defaultValue = _.isNil(mergedProps[prop].default) ? null : mergedProps[prop].default

    const children = []
    let isRepeater = false
    const propName = prop
    if (_.isFunction(defaultValue)) {
      const dValues = defaultValue()
      // Handle object values
      if (_.isPlainObject(dValues)) {
        // Check if we should stringify an object value
        if (stringifyMapper && stringifyMapper[prop]) {
          // Stringify the object and make this a text field
          defaultValue = JSON.stringify(dValues)
        } else {
          // Add child object
          for (const key in dValues) {
            children.push({
              type: _.isBoolean(dValues[key]) ? 'checkbox' : key === 'image' ? 'image-picker' : 'text',
              label: _.startCase(key),
              default: dValues[key],
              info: '',
              prop_variable_name: key,
              is_required: false
            })
          }
        }
      }

      // Handle array value
      if (_.isArray(dValues)) {
        // Toggle repeater flag
        isRepeater = true

        // Grab default repeater object from first index
        for (const key in dValues[0]) {
          children.push({
            type: _.isBoolean(dValues[key]) ? 'checkbox' : key === 'image' ? 'image-picker' : 'text',
            label: _.startCase(key),
            default: dValues[key],
            info: '',
            prop_variable_name: key,
            is_required: false
          })
        }
      }
    }

    const schema = {
      type: isRepeater ? 'repeater' : children && children.length ? null : _.isBoolean(defaultValue) ? 'checkbox' : 'text',
      label: _.startCase(prop),
      default: children && children.length ? null : defaultValue,
      info: propObj.description,
      prop_variable_name: prop,
      children,
      is_required: propObj.required
    }

    propSchemas.push(schema)
  }
  return propSchemas
}

export const removeIds = (obj) => {
  if (obj === null) {
    return
  }

  // If Array loop through for objects
  // and recursively call removeIds
  if (Array.isArray(obj)) {
    obj.forEach((item) => removeIds(item))
  } else if (typeof obj === 'object') {
    // Set object id to null
    if (Object.prototype.hasOwnProperty.call(obj, 'id') && !obj.is_schema) {
      obj.id = null
    }

    // Go through object properties
    // and removeIds for sub-property
    Object.keys(obj).forEach((key) => removeIds(obj[key]))
  }
}

export const findById = (o, id) => {
  if (!o) {
    return
  }

  // Early return
  if (o.id === id || o._id === id) {
    return o
  }

  let result, p
  for (p in o) {
    if (o.hasOwnProperty(p) && typeof o[p] === 'object') {
      result = findById(o[p], id)
      if (result) {
        return result
      }
    }
  }
  return result
}

export const removeAndAlterIds = (obj, accountId, columnWidthId) => {
  // let obj = _.cloneDeep(value)
  if (!obj) {
    return
  }
  // console.log('altering: ', obj)

  // If Array loop through for objects
  // and recursively call removeIds
  if (Array.isArray(obj)) {
    obj.forEach((item) => removeAndAlterIds(item, accountId, columnWidthId))
  } else if (typeof obj === 'object') {
    // console.log('alter obj: ', obj)

    // Set new object id
    if (Object.prototype.hasOwnProperty.call(obj, 'id') && !obj.is_global && !obj.is_schema && !obj.isAccount) {
      obj.id = uuid()

      /*
       * Add an accountId to each object
       * We need an account object when writing to the database
       *
       * @deprecated
       * - We don't need account IDs for relations in sections, rows, etc.
       */
      // if (accountId) {
      //   obj.account = {
      //     id: accountId,
      //     // Add isAccount key to flag not to alter this obj.
      //     // isAccount isn't an Account entity field, so it won't be written to the database.
      //     isAccount: true
      //   }
      // }
    }

    // Set new object _id
    if (Object.prototype.hasOwnProperty.call(obj, '_id') && !obj.is_global && !obj.is_schema && !obj.isAccount) {
      const uid = uuid()
      obj._id = uid
    }

    // Go through object properties
    // and removeIds for sub-property
    Object.keys(obj).forEach((key) => removeAndAlterIds(obj[key], accountId, columnWidthId))
  }
}

export const sortByType = (a, b) => {
  const typeA = a.type
  const typeB = b.type
  if (typeA < typeB) {
    return 1
  }
  if (typeA > typeB) {
    return -1
  }

  // types must be equal
  return 0
}

export const recursivelySort = (a, sortBy = 'index') => {
  if (!a) {
    return
  }

  if (typeof a !== 'object') {
    return
  }

  if (Array.isArray(a)) {
    a.sort((first, second) => first[sortBy] - second[sortBy])
    a.forEach((item) => {
      if (Array.isArray(item)) {
        recursivelySort(item)
      }
    })
  }
  if (typeof a === 'object') {
    Object.keys(a).forEach((key) => {
      recursivelySort(a[key])
    })
  }
}
